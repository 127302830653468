import ajax from '@/utils/ajax.js'

/**
 * 获取角色列表
 * @param {*} params 
 * @returns 
 */
export function getList(params) {
  return ajax.post('/Staff/getList',params)
}

/**
 * 获取详情
 * @param {*} params 
 * @returns 
 */
 export function getDetail(params) {
  return ajax.post('/Staff/detail',params)
}


/**
 * 添加
 * @param {*} params 
 * @returns 
 */
 export function add(params) {
  return ajax.post('/Staff/add',params)
}

/**
 * 修改
 * @param {*} params 
 * @returns 
 */
 export function update(params) {
  return ajax.post('/Staff/update',params)
}


/**
 * 修改状态
 * @param {*} params 
 * @returns 
 */
 export function updateStatus(params) {
  return ajax.post('/Staff/updateStatus',params)
}