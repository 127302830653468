import ajax from '@/utils/ajax.js'

/**
 * 获取角色列表
 * @param {*} params 
 * @returns 
 */
export function getList(params) {
  return ajax.post('/StaffRole/getList',params)
}

/**
 * 获取角色列表
 * @param {*} params 
 * @returns 
 */
export function getMenuList(params) {
  return ajax.post('/Menu/getList',params)
}

/**
 * 获取详情
 * @param {*} params 
 * @returns 
 */
export function getDetail(params) {
  return ajax.post('/StaffRole/detail',params)
}


/**
 * 添加
 * @param {*} params 
 * @returns 
 */
 export function add(params) {
  return ajax.post('/StaffRole/add',params)
}

/**
 * 修改
 * @param {*} params 
 * @returns 
 */
export function update(params) {
  return ajax.post('/StaffRole/update',params)
}

/**
 * 删除
 * @param {*} params 
 * @returns 
 */
export function updateStatus(params) {
  return ajax.post('/StaffRole/updateStatus',params)
}

